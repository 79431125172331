import React from "react";
import "./order.css";
import { useSelector } from "react-redux";
import { getCookie } from "../../Utility/cookies";
import OnlineOrder from "../../Components/OnlineOrder";
import OfflineOrder from "../../Components/OfflineOrder";
import { getTables } from "../../Services/selectors/tables";

export default function Order() {
  const getTableId = getCookie("tableId");

  const tables = useSelector(getTables);

  const idTable = tables.find((table) => table.id === +getTableId);

  return (
    <>
      {getTableId === "null" ||
      getTableId === "" ||
      getTableId === undefined ? (
        <OnlineOrder />
      ) : (
        <OfflineOrder idTable={idTable} />
      )}
    </>
  );
}
