export const getSessionStorageLang = (value) => {
  console.log(value);
  if (value === "") {
    return (value = "en");
  } else {
    return (value = sessionStorage.getItem(value));
  }
};

export const setSessionStorage = (name, value) => {
  const saved = sessionStorage.getItem(name);
  const initialValue = JSON.parse(saved);
  return initialValue || value;
};
