import React from "react";
import { Typography, Button, CardMedia } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTotalPrice } from "../../Services/selectors/cart";
import { getBasket } from "../../Services/selectors/basket";
import { useTranslation } from "react-i18next";
export default function OrderList() {
  const { t } = useTranslation("common");
  const dishesInCart = useSelector(getBasket);
  const totalAmount = useSelector(getTotalPrice);
  let imgNull = require("../../Assets/images/home-img/no-image.png");
  return (
    <>
      <Typography
        color="inherit"
        component={"div"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography
          color="inherit"
          component={"div"}
          className="text-order"
          sx={{ fontSize: "24px!important" }}
        >
          <b>{t("menu.your order")}</b>
        </Typography>
        <Typography color="inherit" component={"div"}>
          <Link exact to="/cart" className="text-link">
            <Button className="btn-edit">{t("menu.edit")}</Button>
          </Link>
        </Typography>
      </Typography>

      <Typography color="inherit" component={"div"} sx={{ marginTop: "30px" }}>
        {dishesInCart.map((dish) => (
          <Typography
            color="inherit"
            component={"div"}
            key={dish.id}
            className="block-menu-order"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <Typography
              color="inherit"
              component={"div"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CardMedia
                component="img"
                src={dish.image_link !== null ? dish.image_link : imgNull}
                sx={{ width: "104px", height: "60px", borderRadius: "15px" }}
              />
              <Typography
                color="inherit"
                component={"div"}
                sx={{ paddingLeft: "15px" }}
              >
                <Typography
                  color="inherit"
                  component={"div"}
                  className="text-order"
                  sx={{
                    fontSize: "14px!important",
                    maxWidth: "200px",
                    width: "100%",
                  }}
                >
                  <b>{dish.title}</b>
                </Typography>
                <Typography
                  color="inherit"
                  component={"div"}
                  className="text-order"
                  sx={{ fontSize: "14px!important", paddingTop: "6px" }}
                >
                  <b>{dish.price}₴</b>
                </Typography>
              </Typography>
            </Typography>
            <Typography
              color="inherit"
              component={"div"}
              className="text-order"
              sx={{ fontSize: "14px!important", paddingRight: "10px" }}
            >
              <b>x {dish.quantity}</b>
            </Typography>
          </Typography>
        ))}
      </Typography>
      <Typography
        variant="inherit"
        component={"div"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography
          variant="inherit"
          component={"div"}
          className="text-order"
          sx={{ fontSize: "18px!important", fontWeight: "400!important" }}
        >
          {t("menu.order price")}
        </Typography>
        <Typography
          variant="inherit"
          component={"div"}
          className="text-order"
          sx={{
            fontSize: "18px!important",
            fontWeight: "400!important",
          }}
        >
          {totalAmount} ₴
        </Typography>
      </Typography>
      <Typography
        variant="inherit"
        component={"div"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "12px",
        }}
      >
        <Typography
          variant="inherit"
          component={"div"}
          className="text-order"
          sx={{ fontSize: "24px!important" }}
        >
          {t("menu.final price")}
        </Typography>
        <Typography
          variant="inherit"
          component={"div"}
          className="text-order"
          sx={{ fontSize: "18px!important" }}
        >
          {totalAmount} ₴
        </Typography>
      </Typography>
      <Typography
        variant="inherit"
        component={"div"}
        sx={{ margin: "20px 0", textAlign: "center" }}
      ></Typography>
    </>
  );
}
