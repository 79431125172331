import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlaces } from "./places";

const fetchRemoteOrder = createAsyncThunk(
  "remoteOrder/fetchRemoteOrder",
  async (id, { dispatch }) => {
    if (id === undefined) {
      const placeTitle = await dispatch(fetchPlaces());
      id = placeTitle.payload[0].id;
    }
    const response = await axios.get(
      `https://qr.me-menu.com/api/get/delivery-payment-types/by/place/${id}`
    );

    return response.data.data.delivery_payment_types;
  }
);

const remoteOrderSlice = createSlice({
  name: "places",
  initialState: {
    list: [],
  },
  reducer: {},
  extraReducers: {
    [fetchRemoteOrder.fulfilled]: (state, action) => {
      state.list = action.payload;
    },
  },
});

export default remoteOrderSlice.reducer;
export { fetchRemoteOrder };
