import { createSelector } from "@reduxjs/toolkit";

export const getCategoryDishes = createSelector(
  (state) => state.categoryDishes.list,
  (list) => list
);

export const getCategoryDishesLoading = createSelector(
  (state) => state.categoryDishes.loading,
  (loading) => loading
);
