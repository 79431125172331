import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  CardMedia,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  Divider,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import CartIcon from "../../Components/CartIcon";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PublicIcon from "@mui/icons-material/Public";
import { setSessionStorage } from "../../Utility/setSessionStorage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "rgb(224,255,255)",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  name: {
    color: "black",
  },
  filterName: {
    marginRight: "20%",
  },
}));

export default function Header({ handleDrawerClose, handleDrawerOpen, open }) {
  const classes = useStyles();
  const theme = useTheme();

  const [lang, setLang] = useState("");

  const { i18n } = useTranslation("common");

  const handleChangeLang = (event) => {
    setLang(event.target.value);
  };

  useEffect(() => {
    sessionStorage.setItem("lang", JSON.stringify(i18n.resolvedLanguage));
  }, [i18n.resolvedLanguage]);

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "white" }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            size="large"
            edge="start"
            onClick={handleDrawerOpen}
            aria-label="menu"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/menu">
            <CardMedia
              component="img"
              height="20"
              src={require("../../Assets/images/nav-img/logo.png")}
              sx={{ width: "125px" }}
            />
          </Link>
          <Link to="/cart">
            <IconButton>
              <CartIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Box sx={{ paddingRight: "20px" }}>
            <CardMedia
              component="img"
              height="20"
              src={require("../../Assets/images/nav-img/logo.png")}
              sx={{ width: "125px" }}
            />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List sx={{ padding: "15px" }}>
          <Box sx={{ m: "1px", minWidth: "110px" }}>
            <FormControl fullWidth>
              <InputLabel>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  component={"div"}
                >
                  <PublicIcon sx={{ paddingRight: "4px" }} />| Language
                </Typography>
              </InputLabel>
              <Select
                value={lang}
                label="language"
                onChange={handleChangeLang}
                sx={{ borderRadius: "17px", letterSpacing: "0.21938em" }}
              >
                <MenuItem
                  value={"en"}
                  onClick={() => i18n.changeLanguage("en")}
                >
                  English
                </MenuItem>
                <MenuItem
                  value={"es"}
                  onClick={() => i18n.changeLanguage("es")}
                >
                  Español
                </MenuItem>
                <MenuItem
                  value={"ka"}
                  onClick={() => i18n.changeLanguage("ka")}
                >
                  ქართული
                </MenuItem>
                <MenuItem
                  value={"kk"}
                  onClick={() => i18n.changeLanguage("kk")}
                >
                  Казақ
                </MenuItem>
                <MenuItem
                  value={"mo"}
                  onClick={() => i18n.changeLanguage("mo")}
                >
                  Moldovean
                </MenuItem>
                <MenuItem
                  value={"ru"}
                  onClick={() => i18n.changeLanguage("ru")}
                >
                  Русский
                </MenuItem>
                <MenuItem
                  value={"uk"}
                  onClick={() => i18n.changeLanguage("uk")}
                >
                  Українська
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      ></main>
    </Box>
  );
}
