import React, { useState, useEffect } from "react";
import "./home.css";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getPlaces } from "../../Services/selectors/places";
import { useDispatch } from "react-redux";
import { Container, Typography, Box, Button, CardMedia } from "@mui/material";
import saladSvg from "../../Assets/images/home-img/salad.svg";
import { setCookies } from "../../Utility/cookies";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { fetchBasket } from "../../Services/slices/basket";
import NotFound from "../../Components/NotFound";

import { useTranslation } from "react-i18next";

export default function Home() {
  const navigate = useNavigate();
  const placeTitle = useSelector(getPlaces);

  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const [boxFirst, setBoxFirst] = useState("");

  const [searchParams] = useSearchParams();

  console.log(placeTitle);

  useEffect(() => {
    dispatch(fetchBasket());
  }, [dispatch]);

  const getTableId = searchParams.get("tableId");
  const getMenuId = searchParams.get("menuId");

  setCookies("tableId", getTableId);
  setCookies("menuId", getMenuId);

  const btnBlockOrder = () => {
    setBoxFirst("background-order-block");
  };

  const menu = "/menu";

  function delayMenu(e) {
    e.preventDefault();

    setTimeout(() => navigate(menu), 300);
  }

  const useStyles = makeStyles((theme) => ({
    cardSizeOrder: {
      width: "48px!important",
      height: "47px!important",
    },

    saladIcon: {
      filter:
        "invert(80%) sepia(9%) saturate(7045%) hue-rotate(204deg) brightness(87%) contrast(86%)!important",
      width: "48px!important",
      height: "47px!important",
    },

    cardSizeReservation: {
      width: "75%!important",
      height: "100%!important",
    },
  }));

  const classes = useStyles();
  if (getMenuId === null || getMenuId === undefined || getMenuId === "") {
    return <NotFound />;
  } else {
    return (
      <>
        <Container maxWidth="xs" sx={{ marginTop: "30px", color: "#3E4957" }}>
          <Box>
            <Typography
              className="font-title"
              variant="inherit"
              component={"div"}
            >
              {placeTitle.title}
            </Typography>
            <Typography
              className="font-text-home-page"
              variant="inherit"
              component={"div"}
              sx={{ mt: 2 }}
            >
              {placeTitle.description}
            </Typography>
          </Box>

          <Box
            className={boxFirst}
            sx={{
              border: "0.5px solid #C0C0C0",
              marginTop: "20px",
              borderRadius: "20px",
            }}
          >
            <Typography
              variant="inherit"
              component={"div"}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                className="icon-btn-block"
                variant="inherit"
                component={"div"}
                sx={{ marginLeft: "25px" }}
              >
                <Box
                  className="icon-btn"
                  sx={{
                    border: "0.5px solid #C0C0C0",
                    margin: "20px 25px 20px 0px",
                    borderRadius: "20px",
                    padding: "27px",
                    background: "rgba(99, 28, 160, 0.15)",
                  }}
                >
                  <CardMedia
                    component="img"
                    className={classes.saladIcon}
                    src={saladSvg}
                  />
                </Box>
              </Typography>
              <Typography variant="inherit" component={"div"}>
                <Box
                  className="icon-btn"
                  sx={{
                    border: "0.5px solid #C0C0C0",
                    margin: "20px 25px 20px 0px",
                    borderRadius: "20px",
                    padding: "27px",
                  }}
                >
                  <CardMedia
                    component="img"
                    className={classes.cardSizeOrder}
                    src={require("../../Assets/images/home-img/drink.png")}
                  />
                </Box>
              </Typography>
              <Typography
                className="icon-btn-block"
                variant="inherit"
                component={"div"}
                sx={{ overflow: "hidden" }}
              >
                <Box
                  className="icon-btn"
                  sx={{
                    border: "0.5px solid #C0C0C0",
                    margin: "20px 0px",
                    borderRadius: "20px",
                    padding: "27px",
                    width: "65px",
                  }}
                >
                  <CardMedia
                    component="img"
                    className={classes.cardSizeOrder}
                    src={require("../../Assets/images/home-img/cake.png")}
                  />
                </Box>
              </Typography>
            </Typography>
            <Typography
              variant="inherit"
              component={"div"}
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <Link to={menu} onClick={delayMenu} className="text-link">
                <Button className="btn" onClick={btnBlockOrder}>
                  {t("menu.make Order")}
                </Button>
              </Link>
            </Typography>
          </Box>

          {/* <Box
            className={boxSecond}
            sx={{
              border: "0.5px solid #C0C0C0",
              marginTop: "20px",
              borderRadius: "20px",
            }}
          >
            <Typography
              variant="inherit"
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CardMedia
                component="img"
                className={classes.cardSizeReservation}
                src={require("../../Assets/images/home-img/card2.png")}
              />
            </Typography>
            <Typography
              variant="inherit"
              component={"div"}
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <Button className="btn" onClick={btnBlockReseravation}>
                reservation
              </Button>
            </Typography>
          </Box> */}
        </Container>
      </>
    );
  }
}
