import React from "react";
import { Typography, Button } from "@mui/material";
import { addCountDish, deleteCountDish } from "../../Services/slices/cart.js";

export default function DishAddBtnModal({ itemQuantity, dish, dispatch, t }) {
  if (itemQuantity === 0 || itemQuantity === undefined) {
    return (
      <Button
        onClick={() => {
          dispatch(addCountDish(dish.id));
        }}
        className="btn-choose"
        sx={{ background: "#e8ddf1!important", color: "#631ca0!important" }}
      >
        <b>+ {t("menu.choose")}</b>
      </Button>
    );
  } else {
    return (
      <Typography
        component={"div"}
        variant="inherit"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography component={"div"} variant="inherit">
          <Button
            onClick={() => {
              dispatch(deleteCountDish(dish.id));
            }}
            sx={{
              backgroundColor: "#D6D6D6!important",
              color: "#fff!important",
            }}
            className="btn-remove-count"
          >
            -
          </Button>
        </Typography>
        <Typography className="bold-text" component={"div"} variant="inherit">
          {itemQuantity}
        </Typography>
        <Typography component={"div"} variant="inherit">
          <Button
            onClick={() => {
              dispatch(addCountDish(dish.id));
            }}
            className="btn-add-modal"
            sx={{
              backgroundColor: "#E8DDF1!important",
              color: "#631ca0!important",
            }}
          >
            +
          </Button>
        </Typography>
      </Typography>
    );
  }
}
