import React from "react";
import { Box, CardMedia, Typography } from "@mui/material";

export default function RemotePaymentListItem({
  typeRemote,
  paymentSelect,
  handleClickPayment,
  setPaymentTypeRemote,
}) {
  setPaymentTypeRemote(paymentSelect);

  function ImgPayType() {
    if (typeRemote.title === "Оплата онлайн") {
      return (
        <CardMedia
          src={require("../../Assets/images/home-img/online.png")}
          component="img"
          sx={{ width: "30px", height: "30px", margin: "0 auto" }}
        />
      );
    } else if (typeRemote.title === "Оплата наличными") {
      return (
        <CardMedia
          src={require("../../Assets/images/home-img/cash.png")}
          component="img"
          sx={{ width: "30px", height: "30px", margin: "0 auto" }}
        />
      );
    } else {
      return (
        <CardMedia
          src={require("../../Assets/images/home-img/online.png")}
          component="img"
          sx={{ width: "30px", height: "30px", margin: "0 auto" }}
        />
      );
    }
  }

  function ImgPayTypePurple() {
    if (typeRemote.title === "Оплата онлайн") {
      return (
        <CardMedia
          src={require("../../Assets/images/home-img/online-purple.png")}
          component="img"
          sx={{ width: "30px", height: "30px", margin: "0 auto" }}
        />
      );
    } else if (typeRemote.title === "Оплата наличными") {
      return (
        <CardMedia
          src={require("../../Assets/images/home-img/cash-purple.png")}
          component="img"
          sx={{ width: "30px", height: "30px", margin: "0 auto" }}
        />
      );
    } else {
      return (
        <CardMedia
          src={require("../../Assets/images/home-img/online-purple.png")}
          component="img"
          sx={{ width: "30px", height: "30px", margin: "0 auto" }}
        />
      );
    }
  }

  return (
    <Box value={typeRemote.id} onClick={handleClickPayment(typeRemote.id)}>
      {paymentSelect !== typeRemote.id ? (
        <Typography
          color="inherit"
          component={"div"}
          className="block-payment-order"
        >
          <ImgPayType />
          <Typography
            component={"div"}
            sx={{
              fontSize: "11px!important",
              marginTop: "6px",
            }}
          >
            <b>{typeRemote.title}</b>
          </Typography>
        </Typography>
      ) : (
        <Typography
          color="inherit"
          component={"div"}
          className="block-payment-order"
          sx={{
            backgroundColor: "rgba(99, 28, 160, 0.15)!important",
            color: "#631CA0!important",
            border: "none!important",
            padding: "12px!important",
          }}
        >
          <ImgPayTypePurple />
          <Typography
            component={"div"}
            sx={{
              fontSize: "11px!important",
              marginTop: "6px",
              color: "#631CA0!important",
            }}
          >
            <b>{typeRemote.title}</b>
          </Typography>
        </Typography>
      )}
    </Box>
  );
}
