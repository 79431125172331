import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import RemotePaymentListItem from "../RemotePaymentListItem";

export default function RemotePaymentList({
  remoteType,
  setPaymentTypeRemote,
}) {
  const [paymentSelect, setPaymentSelect] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setPaymentSelect(remoteType[0].id);
  }, [remoteType, dispatch]);

  const handleClickPayment = (id) => () => {
    setPaymentSelect(() => {
      return id;
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      {remoteType.map((typeRemote) => (
        <RemotePaymentListItem
          key={typeRemote.id}
          typeRemote={typeRemote}
          paymentSelect={paymentSelect}
          handleClickPayment={handleClickPayment}
          setPaymentTypeRemote={setPaymentTypeRemote}
        />
      ))}
    </Box>
  );
}
