import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import OrderList from "../OrderList";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../../Layouts/Footer";
import { useNavigate } from "react-router-dom";
import { makeRemoteOrder } from "../../Services/slices/remoteOrder";
import DateTime from "../DateTime";
import AddPeople from "../AddPeople";
import PersonalData from "../PersonalData";
import SelectPayment from "../SelectPayment";
import TimeDelivery from "../TimeDelivery";
import { setSessionStorage } from "../../Utility/setSessionStorage";
import { useTranslation } from "react-i18next";

export default function OnlineOrder() {
  const dispatch = useDispatch();
  const [name, setName] = useState(setSessionStorage("name", ""));
  const [phone, setPhone] = useState(setSessionStorage("phone", ""));
  const [comments, setComments] = useState(setSessionStorage("comments", ""));
  const [count, setCount] = useState(setSessionStorage("count", 1));
  const [paymentTypeRemote, setPaymentTypeRemote] = useState();
  const [orderType, setOrderType] = useState(
    setSessionStorage("orderType", "delivery")
  );
  const [timeDelivery, setTimeDelivery] = useState(
    setSessionStorage("timeDelivery", "default")
  );
  const [street, setStreet] = useState(setSessionStorage("street", ""));
  const [house, setHouse] = useState(setSessionStorage("house", ""));
  const [floor, setFloor] = useState(setSessionStorage("floor", ""));
  const [apartment, setApartment] = useState(
    setSessionStorage("apartment", "")
  );
  const [date, setDate] = useState(setSessionStorage("date", ""));
  const [time, setTime] = useState(setSessionStorage("time", ""));
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const handleSendPost = (event) => {
    try {
      const formData = new FormData(event.target);
      event.preventDefault();
      formData.set("customer_name", formData.get("customer_name"));
      formData.set("customer_phone", formData.get("customer_phone"));
      formData.set("comments", formData.get("comments"));
      formData.append("persons_number", count);
      formData.append("delivery_payment_type", paymentTypeRemote);
      if (orderType === "delivery") {
        formData.append("order_type", orderType);
        formData.append("street", street);
        formData.append("house", house);
        formData.append("floor", floor);
        formData.append("apartment", apartment);
      } else {
        formData.append("order_type", orderType);
      }
      if (timeDelivery === "default") {
      } else {
        formData.append("date", date);
        formData.append("time", time);
      }

      dispatch(makeRemoteOrder(formData));
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("comments");
      sessionStorage.removeItem("count");
      sessionStorage.removeItem("orderType");
      sessionStorage.removeItem("timeDelivery");
      sessionStorage.removeItem("street");
      sessionStorage.removeItem("house");
      sessionStorage.removeItem("floor");
      sessionStorage.removeItem("apartment");
      sessionStorage.removeItem("date");
      sessionStorage.removeItem("time");
      navigate("/successes", { replace: true });
    } catch (e) {}
  };

  const handleSetOrderType = (event, newType) => {
    setOrderType(newType);
  };

  const handleTimeDelivery = (event, newTime) => {
    setTimeDelivery(newTime);
  };

  useEffect(() => {
    sessionStorage.setItem("name", JSON.stringify(name));
    sessionStorage.setItem("phone", JSON.stringify(phone));
    sessionStorage.setItem("comments", JSON.stringify(comments));
    sessionStorage.setItem("count", JSON.stringify(count));
    sessionStorage.setItem("orderType", JSON.stringify(orderType));
    sessionStorage.setItem("timeDelivery", JSON.stringify(timeDelivery));
    sessionStorage.setItem("street", JSON.stringify(street));
    sessionStorage.setItem("house", JSON.stringify(house));
    sessionStorage.setItem("floor", JSON.stringify(floor));
    sessionStorage.setItem("apartment", JSON.stringify(apartment));
    sessionStorage.setItem("date", JSON.stringify(date));
    sessionStorage.setItem("time", JSON.stringify(time));
  }, [
    apartment,
    comments,
    count,
    date,
    floor,
    house,
    name,
    orderType,
    phone,
    street,
    time,
    timeDelivery,
  ]);

  const useStyles = makeStyles((theme) => ({
    inputField: {
      fontFamily: "Arial!important",
      fontStyle: "normal!important",
      fontWeight: "400!important",
      fontSize: "16px!important",
      lineHeight: "100%!important",
      borderRadius: "10px!important",
      color: "#AEAEB2!important",
    },

    labelLine: {
      position: "absolute!important",
      height: "1px!important",
      bottom: "0!important",
      width: "100%!important",
      transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important",
      backgroundColor: "#C0C0C0!important",
    },
  }));

  const classes = useStyles();

  return (
    <form onSubmit={handleSendPost}>
      <Container
        maxWidth="xs"
        sx={{
          marginTop: "30px",
          color: "#3E4957",
          paddingRight: "0px",
          paddingLeft: "0px",
        }}
      >
        <Box sx={{ paddingRight: "16px", paddingLeft: "16px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="media-order-placement"
          >
            <Typography
              color="inherit"
              component={"div"}
              className={classes.textOrder}
              sx={{ fontSize: "25px!important" }}
            >
              <b> {t("menu.order placement")} </b>
            </Typography>

            <Typography
              color="inherit"
              component={"div"}
              sx={{ paddingRight: "20px" }}
            >
              <Link exact to="/menu" className="text-link">
                <Button
                  className="btn-edit"
                  sx={{
                    background: "#631CA0!important",
                    color: "#fff!important",
                  }}
                >
                  {t("menu.back to menu")}
                </Button>
              </Link>
            </Typography>
          </Box>

          <Box
            className="block-background"
            sx={{
              marginLeft: "0px!important",
              marginTop: "5px!important",
              marginBottom: "5px!important",
            }}
          >
            <Typography sx={{ color: "#631CA0" }}>
              {t("menu.remote order")}
            </Typography>
          </Box>

          <Box>
            <Typography
              color="inherit"
              component={"div"}
              className={classes.textOrder}
              sx={{ fontSize: "24px!important" }}
            >
              <b>{t("menu.personal data")}</b>
            </Typography>
            <TextField
              id="outlined-basic"
              label={t("menu.enter your name")}
              name="customer_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              required
              className={classes.inputField}
              variant="outlined"
              sx={{ marginTop: "10px", width: "100%" }}
            />

            <TextField
              id="outlined-basic"
              required
              name="customer_phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="tel"
              InputProps={{ inputProps: { minLength: 12, maxLength: 14 } }}
              label={t("menu.enter your phone")}
              className={classes.inputField}
              variant="outlined"
              sx={{ marginTop: "10px", width: "100%" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: "40px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <Tabs
            value={orderType}
            onChange={handleSetOrderType}
            textColor="secondary"
            indicatorColor="secondary"
            sx={{ width: "100%" }}
            variant="fullWidth"
            aria-label="secondary tabs example"
          >
            <Tab value="delivery" label={t("menu.delivery")} />
            <span
              className={classes.labelLine}
              sx={{ left: "191px", width: "191px" }}
            ></span>
            <Tab value="pickup" label={t("menu.pickup")} />
            <span
              className={classes.labelLine}
              sx={{ left: "191px", width: "191px" }}
            ></span>
          </Tabs>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          {orderType === "delivery" ? (
            <PersonalData
              classes={classes}
              street={street}
              setStreet={setStreet}
              house={house}
              setHouse={setHouse}
              floor={floor}
              setFloor={setFloor}
              apartment={apartment}
              setApartment={setApartment}
              t={t}
            />
          ) : (
            <></>
          )}
        </Box>

        <TimeDelivery
          handleTimeDelivery={handleTimeDelivery}
          classes={classes}
          timeDelivery={timeDelivery}
          t={t}
        />
        <Box sx={{ paddingRight: "16px", paddingLeft: "16px" }}>
          <Box>
            {timeDelivery === "date_time" ? (
              <DateTime
                classes={classes}
                date={date}
                setDate={setDate}
                time={time}
                setTime={setTime}
              />
            ) : (
              <></>
            )}
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-multiline-static"
                label={t("menu.add comments")}
                name="comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                type="text"
                className={classes.inputField}
                multiline
                rows={6}
                sx={{ marginTop: "10px", width: "100%" }}
              />
            </Box>
          </Box>
        </Box>
        <AddPeople count={count} setCount={setCount} t={t} />
        <Box sx={{ marginTop: "15px" }}>
          <Typography
            color="inherit"
            component={"div"}
            className={classes.textOrder}
            sx={{
              paddingLeft: "15px",
              paddingTop: "15px",
              fontSize: "20px!important",
            }}
          >
            <b> {t("menu.payment type")} </b>
          </Typography>
          <SelectPayment setPaymentTypeRemote={setPaymentTypeRemote} />
        </Box>
        <Box
          className="block-order-finish"
          sx={{ marginTop: "30px", padding: "0 20px" }}
        >
          <OrderList />

          <Button
            type="submit"
            className="btn-order"
            sx={{ width: "100%", marginBottom: "30px" }}
          >
            {t("menu.order")}
          </Button>
        </Box>
        <Box>
          <Footer />
        </Box>
      </Container>
    </form>
  );
}
