const sortItemsDict = {
  title: (items, filter) => {
    return [...items].sort((a, b) => {
      return a[filter].localeCompare(b[filter]);
    });
  },
  price: (items, filter) => {
    return [...items].sort((a, b) => {
      return a[filter] - b[filter];
    });
  }
};

export function sortItems(items, filter) {
  if (!filter) return items;
  return sortItemsDict[filter](items, filter);
}
