import { createSelector } from "@reduxjs/toolkit";
import { getBasket } from "./basket";



export const getTotalCount = createSelector(
  getBasket,
  (dishesInCart) => {
    return dishesInCart.reduce((sum, dish) => {
      return sum + 1 * dish.quantity;
    }, 0)
  }
)

export const getTotalPrice = createSelector(
  getBasket,
  (dishesInCart) => {
    const result = dishesInCart.reduce((sum, dish) => {
      return sum + dish.price * dish.quantity;
    }, 0)
    return result.toFixed(2)
  }
)


