import React from "react";
import { Box, Typography, TextField } from "@mui/material";

export default function PersonalData({
  classes,
  street,
  setStreet,
  house,
  setHouse,
  floor,
  setFloor,
  apartment,
  setApartment,
  t,
}) {
  return (
    <Box>
      <TextField
        id="outlined-basic"
        value={street}
        onChange={(e) => {
          setStreet(e.target.value);
        }}
        required
        className={classes.inputField}
        label={t("menu.street")}
        variant="outlined"
        sx={{ width: "100%" }}
      />
      <Typography
        color="inherit"
        component={"div"}
        sx={{ marginTop: "10px", width: "100%" }}
      >
        <TextField
          id="outlined-basic"
          value={house}
          onChange={(e) => setHouse(e.target.value)}
          type="number"
          label={t("menu.house")}
          variant="outlined"
          className={classes.inputField}
          sx={{ width: "100%" }}
        />
      </Typography>

      <Typography
        color="inherit"
        component={"div"}
        sx={{ marginTop: "10px", width: "100%" }}
      >
        <TextField
          id="outlined-basic"
          value={floor}
          onChange={(e) => setFloor(e.target.value)}
          type="number"
          label={t("menu.floor")}
          variant="outlined"
          className={classes.inputField}
          sx={{ width: "100%" }}
        />
      </Typography>
      <Typography
        color="inherit"
        component={"div"}
        sx={{ marginTop: "10px", width: "100%" }}
      >
        <TextField
          id="outlined-basic"
          value={apartment}
          onChange={(e) => setApartment(e.target.value)}
          type="number"
          label={t("menu.apartment")}
          variant="outlined"
          className={classes.inputField}
          sx={{ width: "100%" }}
        />
      </Typography>
    </Box>
  );
}
