import React from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

export default function NotFound() {
  const useStyles = makeStyles((theme) => ({
    textCart: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "30px",
      lineHeight: "100%",
      color: "#3E4957",
    },
  }));

  const classes = useStyles();

  return (
    <Box sx={{ paddingLeft: "16px", paddingRight: "16px", marginTop: "10px" }}>
      <Typography
        variant="initial"
        component={"div"}
        className={classes.textCart}
      >
        404
      </Typography>
    </Box>
  );
}
