import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCategories } from "./categories";

const fetchSubcategories = createAsyncThunk(
  "subcategories/fetchSubcategories",
  async (id, { dispatch }) => {
    if (id === undefined) {
      const categories = await dispatch(fetchCategories());
      id = categories.payload[0].id;
    }

    const response = await axios.get(
      `https://qr.me-menu.com/app/order/get/only-subcategories/by/category/${id}`
    );
    return response.data.data.subcategories;
  }
);

const subcategoriesSlice = createSlice({
  name: "subcategories",
  initialState: {
    list: [],
  },
  reducer: {},
  extraReducers: {
    [fetchSubcategories.fulfilled]: (state, actoin) => {
      state.list = actoin.payload;
    },
  },
});

export default subcategoriesSlice.reducer;
export { fetchSubcategories };
