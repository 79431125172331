import { Box } from "@mui/material";
import React from "react";
import CartListItem from "../CartListItem";

export default function Cartlist({ cartDishes }) {
  return (
    <Box>
      {cartDishes.map((dish) => (
        <CartListItem key={dish.id} dish={dish} />
      ))}
    </Box>
  );
}
