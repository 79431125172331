import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import LocalPaymentListItem from "../LocalPaymentListItem";

export default function LocalPaymentList({ localType, setPaymentTypeLocal }) {
  const [paymentSelect, setPaymentSelect] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setPaymentSelect(localType[0].id);
  }, [localType, dispatch]);

  const handleClickPayment = (id) => () => {
    setPaymentSelect((state) => {
      if (id === state) return "";
      return id;
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      {localType.map((typeLocal) => (
        <LocalPaymentListItem
          key={typeLocal.id}
          typeLocal={typeLocal}
          paymentSelect={paymentSelect}
          handleClickPayment={handleClickPayment}
          setPaymentTypeLocal={setPaymentTypeLocal}
        />
      ))}
    </Box>
  );
}
