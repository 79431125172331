import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, CardMedia, Button } from "@mui/material";

import {
  addCountDish,
  deleteCountDish,
  removeCart,
} from "../../Services/slices/cart.js";

export default function CartListItem({ dish }) {
  const dispatch = useDispatch();
  const addMoreDishes = () => {
    dispatch(addCountDish(dish.id));
  };

  const removeDishes = () => {
    dish.quantity === 1
      ? window.confirm("Are you sure you want to remove the dish") &&
        dispatch(deleteCountDish(dish.id))
      : dispatch(deleteCountDish(dish.id));
  };

  function deleteCart() {
    window.confirm("Are you sure you want to remove the dish") &&
      dispatch(removeCart(dish.id));
  }

  let imgNull = require("../../Assets/images/home-img/no-image.png");

  return (
    <Box>
      <Box sx={{ marginTop: "20px", padding: "0px 16px" }}>
        <Box>
          <Typography className="menu-item" component={"div"} variant="inherit">
            <Typography
              component={"div"}
              variant="inherit"
              sx={{ display: "flex" }}
            >
              <CardMedia
                component="img"
                src={dish.image_link !== null ? dish.image_link : imgNull}
                sx={{ width: "126px", height: "115px", borderRadius: "20px" }}
              />
              <Typography
                component={"div"}
                variant="inherit"
                className="menu-text-block"
              >
                <Typography component={"div"} variant="inherit">
                  <Typography
                    className="bold-text"
                    component={"div"}
                    variant="inherit"
                    sx={{ maxWidth: "80px", width: "100%" }}
                  >
                    {dish.title}
                  </Typography>
                  <Typography
                    className="opacity-text"
                    component={"div"}
                    variant="inherit"
                    sx={{ paddingTop: "5px", wordBreak: "break-word" }}
                  >
                    {dish.description}
                  </Typography>
                </Typography>
                <Typography
                  className="bold-text"
                  component={"div"}
                  variant="inherit"
                >
                  {dish.price} ₴
                </Typography>
              </Typography>
            </Typography>

            <Typography
              component={"div"}
              variant="inherit"
              className="right-side-block"
            >
              <CardMedia
                onClick={deleteCart}
                component="img"
                src={require("../../Assets/images/home-img/cancel-icon.png")}
                sx={{ width: "12px", height: "12px" }}
              />
              <Typography
                component={"div"}
                variant="inherit"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                {dish.weight !== null ? (
                  <Typography
                    component={"div"}
                    variant="inherit"
                    className="block-weight opacity-text"
                    sx={{ marginTop: "10px" }}
                  >
                    {dish.weight}
                    {dish.unit}
                  </Typography>
                ) : (
                  <Typography></Typography>
                )}
                <Typography
                  component={"div"}
                  variant="inherit"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography component={"div"} variant="inherit">
                    <Button
                      onClick={removeDishes}
                      className="btn-remove-count"
                      sx={{
                        backgroundColor: "#D6D6D6!important",
                        color: "#fff!important",
                      }}
                    >
                      -
                    </Button>
                  </Typography>
                  <Typography
                    className="bold-text"
                    component={"div"}
                    variant="inherit"
                  >
                    {dish.quantity}
                  </Typography>
                  <Typography component={"div"} variant="inherit">
                    <Button
                      onClick={addMoreDishes}
                      sx={{
                        backgroundColor: "#631ca0!important",
                        color: "#fff!important",
                      }}
                      className="btn-add"
                    >
                      +
                    </Button>
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
