import { createSelector } from "@reduxjs/toolkit";

export const getBasket = createSelector(
  (state) => state.basket.list,
  (list) => list
);

export const getBasketLoading = createSelector(
  (state) => state.basket.loading,
  (loading) => loading
);
