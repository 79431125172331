import axios from "axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { fetchCategories } from "./categories"

const fetchCategoryDishes = createAsyncThunk("categoryDishes/fetchCategoryDishes", async (id, { dispatch }) => {
  if (id === undefined) {
    const categories = await dispatch(fetchCategories())
    id = categories.payload[0].id
  }

  const response = await axios.get(`https://qr.me-menu.com/app/order/get/category-items/by/category/${id}`)


  return response.data.data.category_items
})


const categoryDishesSlice = createSlice({
  name: "categoryDishes",
  initialState: {
    loading: false,
    list: [],
    error: null,
  },
  reducer: {},
  extraReducers: {
    [fetchCategoryDishes.pending]: (state) => {
      state.loading = true;
    },
    [fetchCategoryDishes.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload
    },
    [fetchCategoryDishes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload
    }
  }
});

export default categoryDishesSlice.reducer
export { fetchCategoryDishes }
