import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../../Utility/cookies";
import { clearCart } from "./cart";

const getMenuId = getCookie("menuId");

export const makeRemoteOrder = createAsyncThunk(
  "makeRemoteOrder",
  async (form, { dispatch, getState }) => {
    const {
      sessionId: { listSessionId },
    } = getState();

    form.append("PHPSESSID", listSessionId);
    form.append("menuId", getMenuId);

    return await axios({
      method: "post",
      url: "https://qr.me-menu.com/api/post/order-placement/online",
      headers: { "content-type": "multipart/form-data" },
      data: form,
    })
      .then(() => dispatch(clearCart()))
      .catch((error) => {
        this.setState({ error });
      });
  }
);
