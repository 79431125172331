import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./menu.css";
import { useDispatch } from "react-redux";
import CategoryList from "../../Containers/CategoryList";
import SubcategoryList from "../../Containers/SubcategoryList";
import MenuList from "../../Containers/MenuList";
import { useTranslation } from "react-i18next";
import { getTotalCount } from "../../Services/selectors/cart.js";
import { makeStyles } from "@material-ui/core/styles";
import { getCategories } from "../../Services/selectors/categories";
import { getCategoryDishes } from "../../Services/selectors/categoryDishes";
import { getSubcategories } from "../../Services/selectors/subcategories.js";
import { getSubcategoriesDishesLoading } from "../../Services/selectors/subcategoriesDishes";
import { getPlaces } from "../../Services/selectors/places";
import { fetchSubcategoriesDishes } from "../../Services/slices/subcategoriesDishes";
import { fetchSubcategories } from "../../Services/slices/subcategories";
import { fetchLocalOrder } from "../../Services/slices/reqLocalOrder";
import { fetchRemoteOrder } from "../../Services/slices/reqRemoteOrder";
import { fetchBasket } from "../../Services/slices/basket";
import { sortItems } from "../../Utility/filterCat";
import SelectFilter from "../../Components/SelectFilter";
import { Typography, Box, Container } from "@mui/material";
import Loader from "../../Components/Loader";
import BtnCount from "../../Components/BtnCount";

export default function Menu() {
  const categoriesDishes = useSelector(getCategoryDishes);
  const subCategoriesTitles = useSelector(getSubcategories);
  const placeTitle = useSelector(getPlaces);
  const categoriesTitles = useSelector(getCategories);

  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const isLoading = useSelector(getSubcategoriesDishesLoading);

  const [filter, setFilter] = useState("");

  const count = useSelector(getTotalCount);

  const [itemsCategory, setItemsCategory] = useState(() =>
    sortItems(categoriesDishes)
  );

  const handleChange = ({ target }) => {
    setFilter(target.value);
    setItemsCategory(sortItems(categoriesDishes, target.value));
  };

  useEffect(() => {
    dispatch(fetchBasket());
    dispatch(fetchSubcategories());
  }, [dispatch]);

  useEffect(() => {
    setItemsCategory(() => sortItems(categoriesDishes));
  }, [categoriesDishes]);

  useEffect(() => {
    dispatch(
      fetchSubcategoriesDishes(subCategoriesTitles.map((subC) => subC.id))
    );
  }, [dispatch, subCategoriesTitles]);

  useEffect(() => {
    dispatch(fetchLocalOrder(placeTitle.id));
    dispatch(fetchRemoteOrder(placeTitle.id));
  }, [dispatch, placeTitle.id]);

  const useStyles = makeStyles((theme) => ({
    labelFilter: {
      fontFamily: "Arial!important",
      fontStyle: "normal!important",
      fontWeight: "500 !important",
      fontSize: "12px!important",
      color: "#3e4857!important",
      lineHeight: "10px!important",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Container maxWidth="xs" sx={{ marginTop: "30px", color: "#3E4957" }}>
        <Box sx={{ marginBottom: "65px" }}>
          <Typography
            className="font-title"
            variant="inherit"
            component={"div"}
          >
            {placeTitle.title}
          </Typography>

          <Typography
            className="font-title"
            variant="inherit"
            component={"div"}
            sx={{
              fontSize: "24px!important",
              marginTop: "40px",
            }}
          >
            {t("menu.menu")}
          </Typography>
          <Box>
            <CategoryList categories={categoriesTitles} />
          </Box>

          <Box sx={{ maxWidth: "110px" }}>
            <Typography component={"div"} variant="inherit">
              <SelectFilter
                handleChange={handleChange}
                filter={filter}
                classes={classes}
                t={t}
              />
            </Typography>
          </Box>
          {isLoading === true ? (
            <Loader />
          ) : (
            <Box>
              <MenuList items={itemsCategory} />
              <SubcategoryList filter={filter} />
            </Box>
          )}
        </Box>

        <BtnCount count={count} t={t} />
      </Container>
    </>
  );
}
