import React from "react";
import { Typography, Box, CardMedia } from "@mui/material";

export default function CategoryListItem({
  category,
  categoryFilter,
  handleClickCategory,
}) {
  return (
    <Box value={category.id} onClick={handleClickCategory(category.id)}>
      {categoryFilter !== category.id ? (
        <Typography
          className="block-category-menu"
          component={"div"}
          variant="inherit"
          sx={{ textAlign: "center" }}
        >
          <Typography
            component={"div"}
            variant="inherit"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <Typography component={"div"} variant="inherit">
              <CardMedia
                component="img"
                src={category.icon}
                sx={{
                  height: "31px",
                  width: "31px",
                  margin: "0 auto",
                }}
              />
            </Typography>
            <Typography
              component={"div"}
              variant="inherit"
              className="font-title"
              sx={{ fontSize: "12px!important", marginTop: "10px" }}
              value={category.title}
            >
              {category.title}
            </Typography>
          </Typography>
        </Typography>
      ) : (
        <Typography
          className="block-category-menu"
          component={"div"}
          variant="inherit"
          sx={{
            textAlign: "center",
            backgroundColor: "rgba(99, 28, 160, 0.15)!important",
            border: "none!important",
          }}
        >
          <Typography
            component={"div"}
            variant="inherit"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10px",

              color: "#631CA0!important",
            }}
          >
            <Typography component={"div"} variant="inherit">
             <CardMedia
                component="img"
                src={category.icon}
                sx={{
                  height: "31px",
                  width: "31px",
                  margin: "0 auto",
                  filter: "invert(14%) sepia(93%) saturate(4167%) hue-rotate(271deg) brightness(71%) contrast(94%)"
                }}
              />
            </Typography>
            <Typography
              component={"div"}
              variant="inherit"
              className="font-title"
              sx={{
                fontSize: "12px!important",
                marginTop: "10px",
                color: "#631CA0!important",
              }}
              value={category.title}
            >
              {category.title}
            </Typography>
          </Typography>
        </Typography>
      )}
    </Box>
  );
}
