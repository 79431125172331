import React from "react";
import { Box, Typography, TextField } from "@mui/material";

export default function DateTime({ classes, setDate, setTime, time, date }) {
  return (
    <Box>
      <Typography
        variant="inherit"
        component={"div"}
        sx={{ marginTop: "10px" }}
      >
        <TextField
          id="date"
          type="date"
          required
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className={classes.inputField}
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Typography>

      <Typography
        variant="inherit"
        component={"div"}
        sx={{ marginTop: "10px" }}
      >
        <TextField
          id="time"
          type="time"
          required
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className={classes.inputField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300,
          }}
          sx={{ width: "100%" }}
        />
      </Typography>
    </Box>
  );
}
