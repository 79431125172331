import React, { useState, useEffect } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { makeLocalOrder } from "../../Services/slices/localOrder";
import OrderList from "../OrderList";
import Footer from "../../Layouts/Footer";
import AddPeople from "../AddPeople";
import SelectPayment from "../SelectPayment";
import { getCookie } from "../../Utility/cookies";
import { setSessionStorage } from "../../Utility/setSessionStorage";
import { useTranslation } from "react-i18next";

export default function OfflineOrder({ idTable }) {
  const dispatch = useDispatch();
  const [name, setName] = useState(setSessionStorage("name", ""));
  const [comments, setComments] = useState(setSessionStorage("comments", ""));
  const [count, setCount] = useState(setSessionStorage("count", 1));
  const [paymentTypeLocal, setPaymentTypeLocal] = useState();
  const navigate = useNavigate();
  const getTableId = getCookie("tableId");
  const { t } = useTranslation("common");

  const handleSendPost = (event) => {
    const formData = new FormData(event.target);
    event.preventDefault();
    formData.set("customer_name", formData.get("customer_name"));
    formData.set("comments", formData.get("comments"));
    formData.append("persons_number", count);
    formData.append("tableId", getTableId);
    formData.append("payment_type", paymentTypeLocal);

    dispatch(makeLocalOrder(formData));
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("comments");
    sessionStorage.removeItem("count");
    navigate("/successes", { replace: true });
  };

  useEffect(() => {
    sessionStorage.setItem("name", JSON.stringify(name));
    sessionStorage.setItem("comments", JSON.stringify(comments));
    sessionStorage.setItem("count", JSON.stringify(count));
  }, [comments, count, name]);

  const useStyles = makeStyles((theme) => ({
    inputField: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "100%",
      color: "#aeaeb2",
      borderRadius: "10px",
    },
  }));

  const classes = useStyles();

  return (
    <form onSubmit={handleSendPost}>
      <Container
        maxWidth="xs"
        sx={{
          marginTop: "30px",
          color: "#3E4957",
          paddingRight: "0px",
          paddingLeft: "0px",
        }}
      >
        <Box sx={{ paddingRight: "16px", paddingLeft: "16px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="media-order-placement"
          >
            <Typography
              color="inherit"
              component={"div"}
              className={classes.textOrder}
              sx={{ fontSize: "25px!important" }}
            >
              <b> {t("menu.order placement")} </b>
            </Typography>

            <Typography
              color="inherit"
              component={"div"}
              sx={{ paddingRight: "20px" }}
            >
              <Link exact to="/menu" className="text-link">
                <Button
                  className="btn-edit"
                  sx={{
                    background: "#631CA0!important",
                    color: "#fff!important",
                  }}
                >
                  {t("menu.back to menu")}
                </Button>
              </Link>
            </Typography>
          </Box>

          <Box
            className="block-background"
            sx={{
              marginLeft: "0px!important",
              marginBottom: "5px!important",
              marginTop: "5px!important",
            }}
          >
            <Typography sx={{ color: "#631CA0" }}>{idTable.title}</Typography>
          </Box>

          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <TextField
              id="outlined-basic"
              name="customer_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className={classes.inputField}
              label={t("menu.what is your name")}
              variant="outlined"
              sx={{ width: "100%" }}
            />
          </Box>
        </Box>

        <Box sx={{ paddingRight: "16px", paddingLeft: "16px" }}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              id="outlined-multiline-static"
              label={t("menu.add comments")}
              name="comments"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className={classes.inputField}
              type="text"
              multiline
              rows={6}
              sx={{ marginTop: "10px", width: "100%" }}
            />
          </Box>
        </Box>

        <AddPeople count={count} setCount={setCount} t={t} />
        <Box sx={{ marginTop: "15px" }}>
          <Typography
            color="inherit"
            component={"div"}
            className={classes.textOrder}
            sx={{
              paddingLeft: "15px",
              paddingTop: "15px",
              fontSize: "20px!important",
            }}
          >
            <b> {t("menu.payment type")} </b>
          </Typography>
          <SelectPayment setPaymentTypeLocal={setPaymentTypeLocal} />
        </Box>
        <Box
          className="block-order-finish"
          sx={{ marginTop: "30px", padding: "0 20px" }}
        >
          <OrderList />

          <Button
            type="submit"
            className="btn-order"
            sx={{ width: "100%", marginBottom: "30px" }}
          >
            {t("menu.order")}
          </Button>
        </Box>
        <Box>
          <Footer />
        </Box>
      </Container>
    </form>
  );
}
