import { createSelector } from "@reduxjs/toolkit";

export const getSubcategoriesDishes = createSelector(
  (state) => state.subcategoriesDishes.list,
  (list) => list
)

export const getSubcategoriesDishesLoading = createSelector(
  (state) => state.subcategoriesDishes.loading,
  (loading) => loading
);
