import React from "react";
import "./footer.css";
import { Container, Typography, Box, CardMedia } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation("common");
  return (
    <Box
      sx={{
        background: "#FFFFFF",
      }}
    >
      <Container
        Container
        maxWidth="xs"
        sx={{ paddingTop: "25px", color: "#3E4957" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 10px",
            marginBottom: "10px",
          }}
        >
          <Typography
            component={"div"}
            variant="inherit"
            className="text-footer"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <CardMedia
              component="img"
              src={require("../../Assets/images/home-img/privacy.png")}
              sx={{ width: "14.82px", height: "12px", paddingRight: "5px" }}
            />
            {t("menu.privacy Policy")}
          </Typography>

          <Typography
            component={"div"}
            variant="inherit"
            sx={{ display: "flex" }}
          >
            <Typography component={"div"} variant="inherit">
              <CardMedia
                component="img"
                src={require("../../Assets/images/home-img/visa.png")}
                sx={{ width: "37px", height: "22px", padding: "0 5px" }}
              />
            </Typography>
            <Typography component={"div"} variant="inherit">
              <CardMedia
                component="img"
                src={require("../../Assets/images/home-img/master.png")}
                sx={{ width: "37px", height: "22px", padding: "0 5px" }}
              />
            </Typography>
            <Typography component={"div"} variant="inherit">
              <CardMedia
                component="img"
                src={require("../../Assets/images/home-img/paypal.png")}
                sx={{ width: "37px", height: "22px", padding: "0 5px" }}
              />
            </Typography>
          </Typography>
        </Box>

        <Typography
          component={"div"}
          variant="inherit"
          className="text-footer"
          sx={{ textAlign: "center", paddingBottom: "10px" }}
        >
          © 2018-2022, {t("menu.made with ♥️ by")} {""}
          <Typography
            variant="inherit"
            component={"span"}
            sx={{ color: "#631CA0" }}
          >
            {t("menu.Me-Team")} {""}
          </Typography>
          {t("menu.for a better web")}.
        </Typography>
      </Container>
    </Box>
  );
}
