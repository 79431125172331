import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import CategoryListItem from "../CategoryListItem";
import { fetchSubcategories } from "../../Services/slices/subcategories";
import { fetchCategoryDishes } from "../../Services/slices/categoryDishes";
const settings = {
  dots: false,
  infinite: false,
  swipeToSlide: true,
  speed: 600,
  slidesToShow: 3.5,
  initialSlide: 0,
  touchMove: true,
  accessibility: true,
};

export default function CategoryList({ categories }) {
  const [categoryFilter, setCategoryFilter] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setCategoryFilter(categories[0].id);
    dispatch(fetchCategoryDishes(categories[0].id));
  }, [categories, dispatch]);

  const handleClickCategory = (id) => () => {
    dispatch(fetchCategoryDishes(id));
    dispatch(fetchSubcategories(id));
    setCategoryFilter(() => {
      return id;
    });
  };

  return (
    <Slider {...settings}>
      {categories.map((category) => (
        <CategoryListItem
          key={category.id}
          category={category}
          categoryFilter={categoryFilter}
          handleClickCategory={handleClickCategory}
        />
      ))}
    </Slider>
  );
}
