import React from "react";
import { useSelector } from "react-redux";
import LocalPaymentList from "../../Containers/LocalPaymentList";
import RemotePaymentList from "../../Containers/RemotePaymentList";
import { getRemoteOrder } from "../../Services/selectors/remoteOrder";
import { getLocalOrder } from "../../Services/selectors/localOrder";
import { getCookie } from "../../Utility/cookies";

export default function SelectPayment({
  setPaymentTypeLocal,
  setPaymentTypeRemote,
}) {
  const paymentLocalOrder = useSelector(getLocalOrder);
  const paymentRemoteOrder = useSelector(getRemoteOrder);

  const getTableId = getCookie("tableId");

  return (
    <>
      {getTableId === "null" ||
      getTableId === "" ||
      getTableId === undefined ? (
        <RemotePaymentList
          remoteType={paymentRemoteOrder}
          setPaymentTypeRemote={setPaymentTypeRemote}
        />
      ) : (
        <LocalPaymentList
          localType={paymentLocalOrder}
          setPaymentTypeLocal={setPaymentTypeLocal}
        />
      )}
    </>
  );
}
