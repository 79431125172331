import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchBasket } from "./basket";

export const addCountDish = createAsyncThunk(
  "addCountDish",
  async (itemId, { getState, dispatch }) => {
    const {
      basket: { list },
      sessionId: { listSessionId },
    } = getState();

    const itemQuantity = list.find(
      (basketItem) => basketItem.id === itemId
    )?.quantity;

    const formData = new FormData();
    formData.append("item_id", itemId);
    formData.append("is_return_result", true);
    formData.append("item_count", itemQuantity ? itemQuantity + 1 : 1);
    formData.append("PHPSESSID", listSessionId);

    return await axios({
      method: "post",
      url: "https://qr.me-menu.com/api/order/cart/add-item",
      headers: {},
      data: formData,
    })
      .then(() => dispatch(fetchBasket()))
      .catch((error) => {
        this.setState({ error });
      });
  }
);

export const deleteCountDish = createAsyncThunk(
  "deleteOneCart",
  async (itemId, { getState, dispatch }) => {
    const {
      basket: { list },
      sessionId: { listSessionId },
    } = getState();

    const itemQuantity = list.find(
      (basketItem) => basketItem.id === itemId
    )?.quantity;

    const formData = new FormData();
    formData.append("item_id", itemId);
    formData.append("is_return_result", true);
    formData.append("item_count", itemQuantity ? itemQuantity - 1 : 0);
    formData.append("PHPSESSID", listSessionId);

    return await axios({
      method: "post",
      url: "https://qr.me-menu.com/api/order/cart/add-item",
      headers: {},
      data: formData,
    })
      .then(() => dispatch(fetchBasket()))
      .catch((error) => {
        this.setState({ error });
      });
  }
);

export const removeCart = createAsyncThunk(
  "removeCart",
  async (data, { getState, dispatch }) => {
    const {
      sessionId: { listSessionId },
    } = getState();

    const formData = new FormData();
    formData.append("item_id", data);
    formData.append("is_return_result", true);
    formData.append("PHPSESSID", listSessionId);

    return await axios({
      method: "post",
      url: "https://qr.me-menu.com/api/order/cart/remove-item",
      headers: {},
      data: formData,
    })
      .then(() => dispatch(fetchBasket()))
      .catch((error) => {
        this.setState({ error });
      });
  }
);

export const clearCart = createAsyncThunk(
  "clearCart",
  async (id, { getState, dispatch }) => {
    const {
      sessionId: { listSessionId },
    } = getState();

    const formData = new FormData();
    formData.append("is_return_result", true);
    formData.append("PHPSESSID", listSessionId);

    return await axios({
      method: "post",
      url: "https://qr.me-menu.com/api/order/cart/clear-cart",
      data: formData,
      headers: {},
    })
      .then(() => dispatch(fetchBasket()))
      .catch((error) => {
        this.setState({ error });
      });
  }
);
