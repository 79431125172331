import React from "react";
import "../../Pages/Cart/cart.css";
import { Link } from "react-router-dom";
import { Typography, CardMedia, Box, Button } from "@mui/material";
import CartList from "../../Containers/CartList";
import Footer from "../../Layouts/Footer";

export default function DishesContent({
  dishesInBasket,
  deleteAllCart,
  getTableId,
  totalAmount,
  idTable,
  t,
}) {
  if (dishesInBasket.length === 0) {
    return (
      <Box sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
        <Typography variant="initial" component={"div"} className="text-cart">
          {t("menu.your basket is empty")}. {t("menu.go and")} {""}
          <Link to="/menu">{t("menu.buy")}</Link> {t("menu.something")}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Box>
        <Typography
          variant="initial"
          component={"div"}
          className="block-cart-flex"
        >
          <Typography variant="initial" component={"div"} className="text-cart">
            {t("menu.your cart")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color="inherit"
              component={"div"}
              sx={{ paddingRight: "20px" }}
            >
              <Link exact to="/menu" className="text-link">
                <Button
                  className="btn-edit"
                  sx={{
                    background: "#631CA0!important",
                    color: "#fff!important",
                  }}
                >
                  {t("menu.back to menu")}
                </Button>
              </Link>
            </Typography>
            <Typography variant="initial" component={"div"}>
              <CardMedia
                onClick={deleteAllCart}
                component="img"
                src={require("../../Assets/images/home-img/cancel-icon.png")}
                sx={{ width: "18px", height: "18px" }}
              />
            </Typography>
          </Box>
        </Typography>
        <Box className="block-background">
          {getTableId === "null" ||
          getTableId === "" ||
          getTableId === undefined ? (
            <Typography sx={{ color: "#631CA0" }}>
              {t("menu.remote order")}
            </Typography>
          ) : (
            <Typography sx={{ color: "#631CA0" }}>{idTable.title}</Typography>
          )}
        </Box>
      </Box>
      <CartList cartDishes={dishesInBasket} />
      <Box className="block-cart">
        <Typography
          variant="initial"
          component={"div"}
          className="block-cart-flex"
          sx={{
            marginTop: "20px",
          }}
        >
          <Typography
            variant="initial"
            component={"div"}
            className="text-cart"
            sx={{ fontSize: "24px!important" }}
          >
            {t("menu.order price")}
          </Typography>
          <Typography
            variant="initial"
            component={"div"}
            className="text-cart"
            sx={{ fontSize: "24px!important" }}
          >
            {totalAmount} ₴
          </Typography>
        </Typography>
        <Typography
          variant="initial"
          component={"div"}
          sx={{ textAlign: "center", marginTop: "20px", padding: "0 20px" }}
        >
          <Link to="/order" className="text-link">
            <Button className="btn-order" sx={{ width: "100%" }}>
              {t("menu.order")}
            </Button>
          </Link>
        </Typography>
      </Box>
      <Footer />
    </Box>
  );
}
