import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlaces } from "./places";

const fetchTables = createAsyncThunk(
  "tables/fetchTables",
  async (id, { dispatch }) => {
    const places = await dispatch(fetchPlaces());

    const response = await axios.get(
      `https://qr.me-menu.com/app/order/get/place-table/by/place/${places.payload.id}`
    );

    return response.data.data.tables;
  }
);

const tablesSlice = createSlice({
  name: "tables",
  initialState: {
    list: [],
  },
  reducer: {},
  extraReducers: {
    [fetchTables.fulfilled]: (state, action) => {
      state.list = action.payload;
    },
  },
});

export default tablesSlice.reducer;
export { fetchTables };
