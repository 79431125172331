import React from "react";
import { Typography, Box, CardMedia, Modal, Container } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DishAddBtnModal from "../DishAddBtnModal";
import Header from "../../Layouts/Header";

export default function ModalWindow({
  open,
  handleClose,
  dish,
  imgNull,
  isSelected,
  handleClickSelected,
  dispatch,
  classes,
  itemQuantity,
  t,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflow: "scroll",
        position: "absolute",
        display: "block",
        height: "100%",
      }}
    >
      <Box
        sx={{
          color: "#3E4957",
          backgroundColor: "white",
          height: "100%",
        }}
      >
        <Header />
        <Container
          maxWidth="xs"
          sx={{ paddingRight: "0px", paddingLeft: "0px", marginTop: "20px" }}
        >
          <Box sx={{ padding: "20px 0px 0px 20px" }}>
            <Typography
              className="font-text"
              variant="inherit"
              component={"div"}
              onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <CardMedia
                component="img"
                src={require("../../Assets/images/home-img/arrow.png")}
                sx={{
                  width: "9px",
                  height: "9px",
                }}
              />
              <Typography
                variant="inherit"
                component={"div"}
                sx={{ paddingLeft: "10px" }}
              >
                {t("menu.back to menu")}
              </Typography>
            </Typography>
          </Box>
        </Container>
        <Box
          sx={{
            marginTop: "10px",
            borderTop: "0.5px solid #C0C0C0",
            boxSizing: "border-box",
            borderRadius: "20px",
          }}
        >
          <Container>
            <Typography variant="inherit" component={"div"}>
              <CardMedia
                component="img"
                src={dish.image_link ? dish.image_link : imgNull}
                sx={{
                  width: "100%",
                  height: "296px",
                  margin: "0 auto",
                  paddingTop: "20px",
                  borderRadius: "50px",
                }}
              />
            </Typography>
            <Typography
              variant="inherit"
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "30px",
              }}
            >
              <Typography
                className="font-text"
                variant="inherit"
                component={"div"}
                sx={{
                  fontSize: "24px!important",
                }}
              >
                {dish.title}
              </Typography>
              <Typography
                variant="inherit"
                component={"div"}
                onClick={handleClickSelected(dish.id)}
              >
                {!isSelected[dish.id] ? (
                  <FavoriteBorderIcon />
                ) : (
                  <FavoriteIcon sx={{ color: "#FF0000" }} />
                )}
              </Typography>
            </Typography>
            {dish.weight !== null ? (
              <Typography
                component={"div"}
                variant="inherit"
                className="block-weight opacity-text"
                sx={{ marginTop: "10px" }}
              >
                {dish.weight}
                {dish.unit}
              </Typography>
            ) : (
              <Typography></Typography>
            )}
            <Typography
              component={"div"}
              variant="inherit"
              className="opacity-text"
              sx={{
                fontSize: "18px!important",
                wordBreak: "break-word",
                lineHeight: "24px!important",
              }}
            >
              {dish.description}
            </Typography>

            <Box sx={{ marginTop: "20px", marginBottom: "50px" }}>
              <Typography
                component={"div"}
                variant="inherit"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <Typography
                  component={"div"}
                  variant="inherit"
                  className="text-price"
                >
                  {dish.price} ₴
                </Typography>

                <Typography
                  component={"div"}
                  variant="inherit"
                  sx={{ marginTop: "10px", textAlign: "center" }}
                >
                  <DishAddBtnModal
                    dispatch={dispatch}
                    dish={dish}
                    classes={classes}
                    itemQuantity={itemQuantity}
                    t={t}
                  />
                </Typography>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </Modal>
  );
}
