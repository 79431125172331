import React from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

export default function SuccessesOrder() {
  const { t } = useTranslation("common");
  const useStyles = makeStyles((theme) => ({
    textCart: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "30px",
      lineHeight: "100%",
      color: "#3E4957",
    },
  }));

  const classes = useStyles();

  return (
    <Box
      sx={{
        paddingLeft: "16px",
        paddingRight: "16px",
        marginTop: "30px",
        textAlign: "center",
      }}
    >
      <Typography
        variant="initial"
        component={"div"}
        className={classes.textCart}
      >
        {t("menu.thank you for order")}!
        <br />
        <Link to="/menu">{t("menu.back to menu")}</Link>
      </Typography>
    </Box>
  );
}
