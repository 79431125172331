import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const fetchSubcategoriesDishes = createAsyncThunk(
  "subcategoriesDishes/fetchSubcategoriesDishes",
  async (ids) => {
    const requests = ids.map((id) =>
      axios.get(
        `https://qr.me-menu.com/app/order/get/subcategory-items/by/subcategory/${id}`
      )
    );
    const response = await Promise.all(requests);
    const result = response.flatMap(
      (response) => response.data.data.subcategory_items
    );

    return result;
  }
);

const subcategoriesDishesSlice = createSlice({
  name: "subcategoriesDishes",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducer: {},
  extraReducers: {
    [fetchSubcategoriesDishes.pending]: (state) => {
      state.loading = true;
    },
    [fetchSubcategoriesDishes.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [fetchSubcategoriesDishes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default subcategoriesDishesSlice.reducer;
export { fetchSubcategoriesDishes };
