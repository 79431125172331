import React from "react";
import "./cart.css";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/material";
import { getTotalPrice } from "../../Services/selectors/cart";
import { getBasket } from "../../Services/selectors/basket";
import { clearCart } from "../../Services/slices/cart.js";
import { getCookie } from "../../Utility/cookies";
import { getTables } from "../../Services/selectors/tables";

import DishesContent from "../../Components/DishesContent";
import { useTranslation } from "react-i18next";

export default function Cart() {
  const totalAmount = useSelector(getTotalPrice);
  const dishesInBasket = useSelector(getBasket);
  const tables = useSelector(getTables);
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const getTableId = getCookie("tableId");

  const idTable = tables.find((table) => table.id === +getTableId);

  const deleteAllCart = () => {
    window.confirm("Are you sure you want to remove all from cart?") &&
      dispatch(clearCart());
  };

  return (
    <>
      <Container maxWidth="xs" className="container-block">
        <DishesContent
          totalAmount={totalAmount}
          dishesInBasket={dishesInBasket}
          getTableId={getTableId}
          deleteAllCart={deleteAllCart}
          idTable={idTable}
          t={t}
        />
      </Container>
    </>
  );
}
