import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SubCategoryListItem from "../SubcategoryListItem";
import { dishesWithCategories } from "../../Services/selectors/menu";
import Dishes from "../SubcategoryDishesListItem";
import { sortItems } from "../../Utility/filterSubcat.js";

const DishesContent = ({ dishes }) => {
  return dishes.map((dish) => <Dishes key={dish.id} dish={dish} />);
};

export default function SubcategoryList({ filter }) {
  const dishesCategories = useSelector(dishesWithCategories);
  const [dishesCategoryFilter, setDishesCategoryFilter] = useState(
    sortItems(dishesCategories, filter)
  );

  useEffect(() => {
    setDishesCategoryFilter(sortItems(dishesCategories, filter));
  }, [dishesCategories, filter]);

  return (
    <div>
      {dishesCategoryFilter.map(([title, dishes]) => {
        return (
          <div key={title}>
            <SubCategoryListItem title={title} />
            <DishesContent dishes={dishes} />
          </div>
        );
      })}
    </div>
  );
}
