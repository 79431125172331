import React, { useEffect, useState } from "react";
import "./Assets/styles/global-styles.css";

import { useDispatch } from "react-redux";
import { fetchCategoryDishes } from "./Services/slices/categoryDishes";
import { fetchCategories } from "./Services/slices/categories";
import { fetchSubcategories } from "./Services/slices/subcategories";
import { fetchPlaces } from "./Services/slices/places";
import { fetchSubcategoriesDishes } from "./Services/slices/subcategoriesDishes";
import { fetchBasket } from "./Services/slices/basket";
import { fetchSessionId } from "./Services/slices/sessionId";
import { fetchLocalOrder } from "./Services/slices/reqLocalOrder";
import { fetchRemoteOrder } from "./Services/slices/reqRemoteOrder";
import { fetchTables } from "./Services/slices/reqTables";
import Header from "./Layouts/Header";

import MainRoutes from "./Routes";

export default function App() {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategoryDishes());
    dispatch(fetchSubcategories());
    dispatch(fetchCategories());
    dispatch(fetchSubcategoriesDishes());
    dispatch(fetchPlaces());
    dispatch(fetchBasket());
    dispatch(fetchSessionId());
    dispatch(fetchLocalOrder());
    dispatch(fetchRemoteOrder());
    dispatch(fetchTables());
  }, [dispatch]);

  return (
    <div>
      <Header
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <MainRoutes handleDrawerClose={handleDrawerClose} />
    </div>
  );
}
