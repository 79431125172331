import React from "react";
import { Box } from "@mui/material";
import MenuListItem from "../MenuListItem";

export default function MenuList({ items }) {
  return (
    <Box>
      {items.map((item) => (
        <MenuListItem key={item.id} item={item} />
      ))}
    </Box>
  );
}
