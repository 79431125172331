import React from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

export default function SubcategoryListItem({ title }) {
  const useStyles = makeStyles((theme) => ({
    blockBackground: {
      background: "#e8ddf1",
      borderRadius: "6px",
      textAlign: "center",
      color: "#631ca0",
      minWidth: "80px",
      padding: "10px",
      marginTop: "20px",
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.blockBackground}>
      <Typography sx={{ color: "#631CA0" }}>#{title}</Typography>
    </Box>
  );
}
