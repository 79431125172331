import { getSubcategoriesDishes } from "./subcategoriesDishes"
import { getSubcategories } from "./subcategories.js";
import { createSelector } from "@reduxjs/toolkit";


export const dishesWithCategories = createSelector(
  getSubcategoriesDishes,
  getSubcategories,
  (dishes, categories) => {
    return categories.reduce((newObj, category) => {
      const matchedCategories = dishes.filter(d => d.subcategoryId === category.id)
      newObj[category.title] = matchedCategories
      return newObj
    }, {})
  })
