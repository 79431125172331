import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../../Utility/cookies";

const fetchPlaces = createAsyncThunk("places/fetchPlaces", async () => {
  const menuId = getCookie("menuId");

  const response = await axios.get(
    `https://qr.me-menu.com/app/order/get/only-place/by/menu/${menuId}`
  );

  return response.data.data.place;
});

const placesSlice = createSlice({
  name: "places",
  initialState: {
    list: [],
  },
  reducer: {},
  extraReducers: {
    [fetchPlaces.fulfilled]: (state, action) => {
      state.list = action.payload;
    },
  },
});

export default placesSlice.reducer;
export { fetchPlaces };
