import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Menu from "../Pages/Menu";
import Cart from "../Pages/Cart";
import Order from "../Pages/Order";
import SuccessesOrder from "../Components/SuccessesOrder";

export default function MainRoutes({ handleDrawerClose }) {
  return (
    <div onClick={handleDrawerClose}>
      <Routes>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="menu" element={<Menu />} />
        <Route path="cart" element={<Cart />} />
        <Route path="order" element={<Order />} />
        <Route path="successes" element={<SuccessesOrder />} />
      </Routes>
    </div>
  );
}
