import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./Services/createStore";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import common_en from "./Translations/en/common.json";
import common_es from "./Translations/es/common.json";
import common_ka from "./Translations/ka/common.json";
import common_kk from "./Translations/kk/common.json";
import common_mo from "./Translations/mo/common.json";
import common_ru from "./Translations/ru/common.json";
import common_uk from "./Translations/uk/common.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      common: common_en,
    },
    es: {
      common: common_es,
    },
    ka: {
      common: common_ka,
    },
    kk: {
      common: common_kk,
    },
    mo: {
      common: common_mo,
    },
    ru: {
      common: common_ru,
    },
    uk: {
      common: common_uk,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
