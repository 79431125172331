import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, CardMedia } from "@mui/material";
import { getBasket } from "../../Services/selectors/basket.js";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DishAddBtn from "../../Components/DishAddBtn";
import ModalSubcategory from "../../Components/ModalWindow";
import { useTranslation } from "react-i18next";

export default function SubcategoryDishesListItem({ dish }) {
  const dispatch = useDispatch();

  const { t } = useTranslation("common");

  const basket = useSelector(getBasket);

  const itemQuantity = basket.find(
    (basketItem) => basketItem.id === dish.id
  )?.quantity;

  const [isSelected, setIsSelected] = useState(
    JSON.parse(localStorage.getItem("isSelected")) || {}
  );

  const handleClickSelected = (title) => () => {
    setIsSelected((state) => {
      const newState = { ...state, [title]: !state[title] };
      localStorage.setItem("isSelected", JSON.stringify(newState));
      return newState;
    });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let imgNull = require("../../Assets/images/home-img/no-image.png");

  return (
    <Box>
      <Box sx={{ marginTop: "10px" }}>
        <Box>
          <Typography className="menu-item" component={"div"} variant="inherit">
            <Typography
              component={"div"}
              variant="inherit"
              sx={{ display: "flex" }}
            >
              <CardMedia
                component="img"
                onClick={handleOpen}
                src={dish.image_link ? dish.image_link : imgNull}
                sx={{ width: "126px", height: "115px", borderRadius: "25px" }}
              />
              <Typography
                component={"div"}
                variant="inherit"
                className="menu-text-block"
              >
                <Typography component={"div"} variant="inherit">
                  <Typography
                    className="bold-text"
                    component={"div"}
                    variant="inherit"
                    onClick={handleOpen}
                    sx={{ maxWidth: "80px", width: "100%" }}
                  >
                    {dish.title}
                  </Typography>
                  <ModalSubcategory
                    open={open}
                    handleClose={handleClose}
                    dish={dish}
                    imgNull={imgNull}
                    isSelected={isSelected}
                    handleClickSelected={handleClickSelected}
                    dispatch={dispatch}
                    itemQuantity={itemQuantity}
                    t={t}
                  />
                  <Typography
                    className="opacity-text"
                    component={"div"}
                    variant="inherit"
                    sx={{
                      paddingTop: "5px",
                      maxWidth: "140px",
                      width: "100%",
                      wordBreak: "break-word",
                    }}
                  >
                    {dish.description}
                  </Typography>
                </Typography>
                <Typography
                  className="bold-text"
                  component={"div"}
                  variant="inherit"
                >
                  {dish.price} ₴
                </Typography>
              </Typography>
            </Typography>
            <Typography
              component={"div"}
              variant="inherit"
              className="right-side-block"
            >
              <Typography
                component={"div"}
                variant="inherit"
                sx={{ paddingRight: "5px" }}
                onClick={handleClickSelected(dish.id)}
              >
                {!isSelected[dish.id] ? (
                  <FavoriteBorderIcon sx={{ fontSize: "1rem!important" }} />
                ) : (
                  <FavoriteIcon sx={{ color: "#FF0000" }} />
                )}
              </Typography>
              <Typography
                component={"div"}
                variant="inherit"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                {dish.weight !== null ? (
                  <Typography
                    component={"div"}
                    variant="inherit"
                    className="block-weight opacity-text"
                    sx={{ marginTop: "10px" }}
                  >
                    {dish.weight}
                    {dish.unit}
                  </Typography>
                ) : (
                  <Typography></Typography>
                )}
                <Typography component={"div"} variant="inherit">
                  <DishAddBtn
                    dispatch={dispatch}
                    dish={dish}
                    itemQuantity={itemQuantity}
                    t={t}
                  />
                </Typography>
              </Typography>
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
