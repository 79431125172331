import React from "react";

import { Box, Typography, Button } from "@mui/material";

export default function AddPeople({ count, setCount, t }) {
  const addPeopleCount = () => {
    setCount(count + 1);
  };

  const removePeopleCount = () => {
    setCount(count - 1);
  };
  if (count >= 2) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Typography
          color="inherit"
          component={"div"}
          sx={{ paddingRight: "15px" }}
        >
          {t("menu.number of persons")}
        </Typography>
        <Typography
          color="inherit"
          component={"div"}
          className="block-order-count"
          sx={{
            display: "flex",
            width: "145px",
            borderLeft: "none",
            justifyContent: "space-between",
          }}
        >
          <Typography color="inherit" component={"div"}>
            <Button
              sx={{ minWidth: "36px", height: "46px", margin: "-1px" }}
              className="btn-order-add"
              onClick={removePeopleCount}
            >
              -
            </Button>
          </Typography>
          <Typography
            color="inherit"
            component={"div"}
            sx={{
              paddingTop: "10px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            {count}
          </Typography>
          <Typography color="inherit" component={"div"}>
            <Button
              sx={{ minWidth: "36px", height: "46px", margin: "-1px" }}
              className="btn-order-add"
              onClick={addPeopleCount}
            >
              +
            </Button>
          </Typography>
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Typography
          color="inherit"
          component={"div"}
          sx={{ paddingRight: "25px" }}
        >
          {t("menu.number of persons")}
        </Typography>
        <Typography
          color="inherit"
          component={"div"}
          className="block-order-count"
          sx={{ display: "flex", width: "90px", justifyContent: "flex-end" }}
        >
          <Typography
            color="inherit"
            component={"div"}
            sx={{
              paddingTop: "10px",
              paddingRight: "14px",
              paddingLeft: "14px",
            }}
          >
            {count}
          </Typography>
          <Typography color="inherit" component={"div"}>
            <Button
              sx={{ minWidth: "36px", height: "46px", margin: "-1px" }}
              className="btn-order-add"
              onClick={addPeopleCount}
            >
              +
            </Button>
          </Typography>
        </Typography>
      </Box>
    );
  }
}
