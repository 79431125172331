import React from "react";
import { Box, Typography } from "@mui/material";
import StyledFormControlLabel from "../StyledFormControlLabel";

export default function TimeDelivery({
  classes,
  handleTimeDelivery,
  timeDelivery,
  t,
}) {
  return (
    <Box
      sx={{
        marginTop: "30px",
        paddingRight: "16px",
        paddingLeft: "16px",
      }}
    >
      <Typography
        color="inherit"
        component={"div"}
        className={classes.textOrder}
        sx={{
          paddingTop: "15px",
          fontSize: "20px!important",
        }}
      >
        <b>{t("menu.time delivery")}</b>
      </Typography>
      <Typography color="inherit" component={"div"} sx={{ paddingTop: "15px" }}>
        <StyledFormControlLabel
          handleTimeDelivery={handleTimeDelivery}
          timeDelivery={timeDelivery}
          t={t}
        />
      </Typography>
    </Box>
  );
}
