import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlaces } from "./places";

const fetchLocalOrder = createAsyncThunk(
  "localOrder/fetchLocalOrder",
  async (id, { dispatch }) => {
    if (id === undefined) {
      const placeTitle = await dispatch(fetchPlaces());
      id = placeTitle.payload[0].id;
    }
    const response = await axios.get(
      `https://qr.me-menu.com/api/get/payment-types/by/place/${id}`
    );

    return response.data.data.payment_types;
  }
);

const localOrderSlice = createSlice({
  name: "places",
  initialState: {
    list: [],
  },
  reducer: {},
  extraReducers: {
    [fetchLocalOrder.fulfilled]: (state, action) => {
      state.list = action.payload;
    },
  },
});

export default localOrderSlice.reducer;
export { fetchLocalOrder };
