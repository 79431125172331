import React from "react";
import { Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";

export default function SelectFilter({ handleChange, filter, classes, t }) {
  return (
    <Box>
      <FormControl sx={{ m: 1, minWidth: "110px" }}>
        <InputLabel
          className={classes.labelFilter}
          id="demo-simple-select-label"
        >
          {t("menu.filter")}
        </InputLabel>
        <Select
          sx={{ height: "40px", borderRadius: "12px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label={t("menu.filter")}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>{t("menu.none")}</em>
          </MenuItem>
          <MenuItem value={"price"}>{t("menu.by Price")}</MenuItem>
          <MenuItem value={"title"}>{t("menu.by title")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
