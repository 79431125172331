import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";

export default function BtnCount({ count, t }) {
  return (
    <>
      {count > 0 ? (
        <Box>
          <Link to="/cart" className="text-link">
            <Button
              className="btn-order"
              sx={{
                width: "80%",
                fontSize: "13px!important",
                padding: "10px!important",
                position: "fixed",
                bottom: "10px",
                right: "10%",
              }}
            >
              {t("menu.your order")}
              <Typography
                variant="inherit"
                component={"span"}
                sx={{
                  marginLeft: "5px",
                  backgroundColor: "white",
                  borderRadius: "13px",
                  padding: "5px",
                  color: "#631ca0 ",
                }}
              >
                <b>{count}</b>
              </Typography>
            </Button>
          </Link>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
