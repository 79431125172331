import React from "react";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { getTotalCount } from "../../Services/selectors/cart.js";
import Badge from "@mui/material/Badge";
export default function CartIcon() {
  const count = useSelector(getTotalCount);
  if (count > 0) {
    return (
      <Badge badgeContent={count} color="primary">
        <ShoppingCartIcon color="inherit" />
      </Badge>
    );
  } else {
    return <ShoppingCartIcon color="inherit" />;
  }
}
