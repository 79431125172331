import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const fetchBasket = createAsyncThunk(
  "basket/fetchBasket",
  async (itemId, { getState, dispatch }) => {
    const {
      sessionId: { listSessionId },
    } = getState();

    const response = await axios.get(
      `https://qr.me-menu.com/ru/api/order/cart/get-cart`,
      {
        params: {
          PHPSESSID: listSessionId,
        },
      }
    );
    return response.data.data.cart.items;
  }
);

const basketSlice = createSlice({
  name: "basket",
  initialState: {
    loading: false,
    list: [],
    error: null,
  },
  reducer: {},
  extraReducers: {
    [fetchBasket.pending]: (state) => {
      state.loading = true;
    },
    [fetchBasket.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [fetchBasket.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default basketSlice.reducer;
export { fetchBasket };
