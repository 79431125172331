const sortItemsDict = {
  title: (items, filter) => {
    const newObj = Object.entries(items).map(([key, values]) => {
      const newArr = [...values].sort((a, b) => {
        return a[filter].localeCompare(b[filter]);
      });
      return [key, newArr]
    })
    return newObj
  },

  price: (items, filter) => {
    const newObj = Object.entries(items).map(([key, values]) => {
      const newArr = [...values].sort((a, b) => {
        return a[filter] - b[filter];
      });
      return [key, newArr]
    })
    return newObj
  }
};

export function sortItems(items, filter) {
  if (!filter) return Object.entries(items);
  return sortItemsDict[filter](items, filter);
}
