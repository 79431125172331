import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie, setCookies, deleteCookie } from "../../Utility/cookies";

const fetchSessionId = createAsyncThunk(
  "sessionId/fetchSessionId",
  async (sessionId, { getState, dispatch }) => {
    const {
      sessionId: { listSessionId },
    } = getState();

    setTimeout(() => {
      deleteCookie("session_id");
    }, 2400000);

    if (getCookie("session_id", listSessionId)) {
      return getCookie("session_id", listSessionId);
    } else {
      const response = await axios.get(
        "https://qr.me-menu.com/api/order/cart/get-new-session-cart-id"
      );
      setCookies("session_id", response.data.data.session_id);
      return response.data.data.session_id;
    }
  }
);

const sessionIdSlice = createSlice({
  name: "places",
  initialState: {
    listSessionId: [],
  },
  reducer: {},
  extraReducers: {
    [fetchSessionId.fulfilled]: (state, action) => {
      state.listSessionId = action.payload;
    },
  },
});

export default sessionIdSlice.reducer;
export { fetchSessionId };
