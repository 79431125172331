import { configureStore, combineReducers } from "@reduxjs/toolkit";
import categoryDishesReducer from "./slices/categoryDishes";
import subcategoriesReducer from "./slices/subcategories";
import categoriesReducer from "./slices/categories";
import placeReducer from "./slices/places";
import subcategoriesDishesReducer from "./slices/subcategoriesDishes";
import basketReducer from "./slices/basket";
import remoteOrderReducer from "./slices/reqRemoteOrder";
import localOrderReducer from "./slices/reqLocalOrder";
import sessionIdReducer from "./slices/sessionId";
import tablesReducer from "./slices/reqTables";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  subcategoriesDishes: subcategoriesDishesReducer,
  categoryDishes: categoryDishesReducer,
  subcategories: subcategoriesReducer,
  categories: categoriesReducer,
  place: placeReducer,
  basket: basketReducer,
  remoteOrder: remoteOrderReducer,
  localOrder: localOrderReducer,
  sessionId: sessionIdReducer,
  tables: tablesReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
